import React from 'react';
import BasePage from '@/components/BasePage';
import dynamic from 'next/dynamic';

// import ImageGallery from 'react-image-gallery';
import { Row, Col, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Translation Higher Order Component
import { translate } from 'react-switch-lang';

const ImageGallery = dynamic(() => import('react-image-gallery'), {
  ssr: false,
});
const images = [
  {
    original: 'images/photo1.webp',
    thumbnail: 'images/photo1_low.webp',
    originalAlt: '',
    thumbnailAlt: '',
    sizes: '500',
  },
  {
    original: 'images/photo2.webp',
    thumbnail: 'images/photo2_low.webp',
    originalAlt: '',
    thumbnailAlt: '',
  },
  {
    original: 'images/photo3.webp',
    thumbnail: 'images/photo3_low.webp',
    originalAlt: '',
    thumbnailAlt: '',
  },
  {
    original: 'images/photo4.webp',
    thumbnail: 'images/photo4_low.webp',
    originalAlt: '',
    thumbnailAlt: '',
  },
  {
    original: 'images/photo5.webp',
    thumbnail: 'images/photo5_low.webp',
    originalAlt: '',
    thumbnailAlt: '',
  },
];

const About = ({ t }) => {
  return (
    //React.Fragment is used instead of <div> or <> to hide it from source
    <React.Fragment>
      {/* Base Page - Shared component with Container */}
      <BasePage id='about-page' className='about-page pt-5' role='article'>
        <Container>
          <article>
            <Row>
              <Col md='6'>
                <h2 className='page_text font-weight-bold'>
                  <FontAwesomeIcon icon={['fas', 'address-card']} /> &nbsp;
                  {t('about.title')}
                </h2>
                <p className='page_subtext text-uppercase'>
                  {t('about.text1')}
                </p>
                <hr className='vaccination-page_hr' />
                <p className='float-left'>
                  {t('about.text2')}
                  <br />
                  <br />
                  {t('about.text3')}
                  <br />
                  <br />
                </p>
              </Col>

              <Col md='6' className='about-page_gallery'>
                <ImageGallery
                  showNav={false}
                  showPlayButton={false}
                  items={images}
                />
              </Col>
            </Row>
          </article>
        </Container>
      </BasePage>
    </React.Fragment>
  );
};

export default translate(About);
